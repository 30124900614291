document.addEventListener('DOMContentLoaded', () => {
  // find out how many buttons
  const DAY_OF_WEEK_ARRAY = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  const DAY_OF_WEEK_BTN = document.querySelector('.day-of-week-btn');
  const DAY_OF_WEEK_BTN_TEXT = document.querySelector('.day-of-week-btn b');
  const JAMES_SOUND_ARRAY = 'jamesSoundArray';
  const JAMES_STATE = 'jamesState';
  const TOTAL_BTNS = Array.from(document.querySelectorAll('[class^="btn-"]'));
  const TOTAL_AUDS = Array.from(document.querySelector('audio'));

  let state = {
    playing: null
  };

  let setUpPlayability = () => {
    TOTAL_BTNS.forEach((btn, index) => {
      btn.addEventListener('pointerdown', () => {
        if (state.playing) {
          state.playing.pause();
          state.playing.currentTime = 0;
          state.playing = null;
        }

        state.playing = btn[JAMES_SOUND_ARRAY][btn[JAMES_STATE].nextAudio];
        if (btn[JAMES_STATE].nextAudio < btn[JAMES_STATE].maxAudio) {
          btn[JAMES_STATE].nextAudio = btn[JAMES_STATE].nextAudio + 1;
        } else {
          btn[JAMES_STATE].nextAudio = 0;
        }
        state.playing.play();
      });
    });

    TOTAL_AUDS.forEach((aud, index) => {
      aud.addEventListener('complete', () => {
        state.playing = null;
        aud.currentTime = 0;
      });
    });
  };

  let setUpDOTWB = () => {
    const DAY_OF_WEEK = DAY_OF_WEEK_ARRAY[new Date().getDay()];
    const SOUND_OF_DAY = document.querySelector(`#aud-${DAY_OF_WEEK}`);

    DAY_OF_WEEK_BTN_TEXT.innerText = DAY_OF_WEEK;

    DAY_OF_WEEK_BTN.addEventListener('pointerdown', () => {
      if (state.playing) {
        state.playing.pause();
        state.playing.currentTime = 0;
        state.playing = null;
      }

      state.playing = SOUND_OF_DAY;
      SOUND_OF_DAY.currentTime = 0.0000001;
      SOUND_OF_DAY.play();
    });
  };

  let init = () => {
    TOTAL_BTNS.forEach((btn, index) => {
      let btnNum = index + 1;
      let counter = 0;
      let audTotal = Number(btn.getAttribute('data-audio-count'));

      btn[JAMES_SOUND_ARRAY] = [];
      btn[JAMES_STATE] = {
        nextAudio: 0,
        maxAudio: (audTotal - 1)
      };

      while (counter < audTotal) {
        btn[JAMES_SOUND_ARRAY].push(document.querySelector(`#aud-${btnNum}${String.fromCharCode(97 + counter)}`));

        counter++;
      }
    });

    setUpDOTWB();
    setUpPlayability();
  }

  init();
}, false);
